
import { defineComponent, onMounted } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "donors",
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Gestion de Donantes", ["Donantes"]);
      MenuComponent.reinitialization();
    });
  },
});
